import * as React from "react";
import styled, { keyframes } from "styled-components";
import { media } from "../GlobalStyle/media";

const dashSpacing = 1400;
const dashLength = 420; // NIce
const dashOffset = dashSpacing + dashLength;

const svgTurn = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingMovement = keyframes`
  0% {
    stroke-dashoffset: 0;
    filter: url("#sketchy-0");
  }
  20% {
    filter: url("#sketchy-1");
  }
  40% {
    filter: url("#sketchy-2");
  }
  60% {
    filter: url("#sketchy-3");
  }
  80% {
    filter: url("#sketchy-4");
  }
  100% {
    filter: url("#sketchy-5");
    stroke-dashoffset: ${dashOffset};  
  }
`;

const loadingMovementLow = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: ${dashOffset};  
  }
`;

const StyledSvg = styled.svg`
  animation: ${svgTurn} 45s linear infinite;

  #animate {
    stroke-dasharray: ${dashLength} ${dashSpacing};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${loadingMovementLow} 3s linear infinite;

    /* TODO: test this */
    ${media.mediumLarge`
      animation: ${loadingMovement} 3s linear infinite;
    `}
  }
`;

const octaves = 1;
const baseFeq = 0.02;
const scale = 8;

const Loading: React.FC = () => (
  <StyledSvg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="440px"
    height="440px"
    viewBox="-5 -5 450 450"
    enableBackground="new 0 0 612 792"
  >
    <filter id="sketchy-0">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="0"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <filter id="sketchy-1">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="1"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <filter id="sketchy-2">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="2"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <filter id="sketchy-3">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="3"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <filter id="sketchy-4">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="4"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <filter id="sketchy-5">
      <feTurbulence
        id="turbulence"
        baseFrequency={baseFeq}
        numOctaves={octaves}
        result="noise"
        seed="5"
      />
      <feDisplacementMap in="SourceGraphic" in2="noise" scale={scale} />
    </filter>
    <path
      id="animate"
      fill="#FFc600"
      stroke="#FFF"
      strokeWidth="10"
      strokeMiterlimit="10"
      d="M50.021,83.862c3.827,0.611,7.709,0.99,11.465,1.893
			c4.462,1.072,8.774,1.058,13.252,0.076c5.195-1.139,10.505-1.743,15.734-2.743c5.274-1.008,6.846-2.833,6.757-8.158
			c-0.104-6.358,0.79-12.472,2.994-18.464c2.723-7.404,3.655-15.102,3.386-23.006c-0.228-6.724,2.264-9.099,8.686-7.19
			c6.781,2.015,13.236,5.175,19.749,8.029c5.295,2.32,10.459,4.941,15.695,7.399c2.754,1.293,5.528,2.545,8.311,3.777
			c3.472,1.538,5.845,0.92,7.284-2.439c3.115-7.271,9.323-12.197,13.638-18.549c4.854-7.147,9.923-14.15,14.819-21.27
			c2.452-3.566,5.127-4.336,8.292-1.453c3.915,3.567,7.325,7.682,11.093,11.417c2.871,2.845,5.972,5.454,8.947,8.195
			c2.912,2.682,5.84,5.348,8.662,8.122c1.004,0.988,1.699,2.286,2.572,3.414c4.484,5.8,7.057,6.202,13.213,2.25
			c5.764-3.7,11.605-7.28,17.367-10.982c7.178-4.611,14.318-9.284,21.461-13.949c3.096-2.021,6.191-1.461,7.037,2.093
			c2.348,9.86,8.107,18.748,8.18,29.26c0.014,1.912,0.988,3.841,1.645,5.718c1.408,4.02,2.982,7.984,4.334,12.022
			c1.318,3.938,3.768,5.545,8.104,4.851c8.344-1.334,16.635-3.165,25.025-3.999c8.355-0.831,16.225-2.894,23.91-6.145
			c3.648-1.543,6.578,0.344,6.35,4.291c-0.344,5.936-1.359,11.835-2.174,17.739c-0.684,4.971-1.672,9.906-2.189,14.892
			c-0.619,5.95-1.006,11.931-1.256,17.908c-0.219,5.234,2.125,7.654,7.383,8.024c1.42,0.1,2.879,0.156,4.277-0.059
			c8.256-1.274,15.764,1.047,23.176,4.375c5.193,2.332,10.561,4.297,15.912,6.257c5.691,2.085,6.836,4.232,3.932,9.653
			c-4.129,7.702-8.842,15.089-12.947,22.802c-3.031,5.694-5.613,11.639-8.152,17.576c-1.436,3.357-0.135,6.002,3.23,8.54
			c6.061,4.571,12.199,9.064,17.988,13.964c3.311,2.803,5.902,6.439,8.996,9.521c2.346,2.335,4.887,4.493,7.494,6.537
			c3.705,2.907,4.014,5.601,0.512,8.662c-2.715,2.372-5.533,4.665-8.51,6.692c-10.162,6.917-19.316,15.014-28.326,23.333
			c-3.701,3.417-4.238,7.204-2.299,11.31c2.836,6,5.914,11.913,9.303,17.616c2.945,4.957,6.35,9.659,9.773,14.31
			c2.357,3.201,3.639,6.665,3.721,10.574c0.07,3.479-1.889,5.634-5.314,5.118c-8.539-1.286-16.045,1.639-23.721,4.681
			c-4.156,1.647-8.744,2.479-13.215,2.98c-4.369,0.49-8.066,2.04-11.408,4.704c-2.199,1.755-2.174,4.229-1.52,6.553
			c1.453,5.16,0.869,10.076-0.607,15.152c-0.826,2.846-0.889,6.035-0.699,9.032c0.516,8.147,1.293,16.28,2.082,24.407
			c0.354,3.626-1.018,5.492-4.758,5.361c-6.523-0.228-12.74-1.84-18.76-4.423c-2.018-0.866-4.387-1.377-6.568-1.299
			c-4.725,0.169-9.217-0.313-13.738-1.858c-3-1.025-6.324-1.239-9.529-1.496c-3.262-0.261-4.79,1.088-5.897,4.21
			c-3.707,10.469-7.238,21.011-11.32,31.333c-1.943,4.91-4.865,9.455-7.564,14.033c-1.582,2.684-4.006,2.957-6.871,0.99
			c-7.369-5.059-14.646-10.252-22.041-15.275c-2.188-1.486-4.73-2.445-6.951-3.895c-3.236-2.113-6.48-4.268-9.441-6.742
			c-2.91-2.43-5.529-2.836-8.395-0.377c-7.426,6.375-14.992,12.607-22.091,19.33c-4.11,3.891-7.391,8.654-11.122,12.957
			c-1.363,1.572-2.813,3.131-4.467,4.373c-2.335,1.752-4.69,1.551-6.216-0.855c-2.581-4.07-5.16-8.236-6.985-12.672
			c-3.974-9.664-6.975-19.768-14.531-27.549c-1.372-1.413-2.261-3.286-3.43-4.907c-2.233-3.098-4.376-4.059-7.887-2.568
			c-3.016,1.28-5.858,3.144-8.478,5.14c-3.377,2.576-6.897,4.4-11.234,4.111c-4.425-0.297-7.802,2.125-11.264,4.277
			c-5.596,3.475-11.963,3.57-18.178,3.941c-1.296,0.078-3.286-1.914-3.973-3.406c-1.049-2.275-1.392-4.924-1.772-7.454
			c-1.354-9.003-3.775-17.858-3.258-27.122c0.214-3.843-0.139-7.77-0.698-11.591c-0.745-5.087-2.79-6.774-7.939-6.551
			c-4.544,0.196-9.059,1.134-13.603,1.293c-3.059,0.107-6.161-0.468-9.217-0.924c-4.009-0.597-8.005-1.308-11.981-2.099
			c-3.994-0.794-7.978-1.659-11.922-2.665c-2.247-0.573-3.195-2.258-2.903-4.577c1.5-11.908,1.415-24.108,7.239-35.177
			c1.618-3.074,2.407-6.604,3.423-9.971c1.032-3.42,0.236-6.028-2.847-7.936c-12.438-7.696-24.681-15.795-38.815-20.254
			c-0.906-0.286-1.796-0.638-2.661-1.03c-3.137-1.426-4.071-3.701-2.345-6.665c5.007-8.601,9.733-17.269,17.192-24.329
			c4.95-4.686,8.468-10.943,12.276-16.729c0.864-1.312,0.896-3.853,0.244-5.368c-3.532-8.209-7.069-16.561-13.014-23.307
			c-2.697-3.061-6.79-5.062-10.539-6.978c-3.974-2.03-6.828-4.737-8.178-8.989c-1.013-3.188-0.163-5.605,2.317-7.902
			c6.722-6.225,14.858-8.965,23.631-11.089c6.928-1.677,13.478-5.063,20.048-8.018c4.408-1.981,5.198-4.641,3.753-9.364
			c-3.422-11.189-6.598-22.414-7.946-34.118c-0.48-4.171-2.074-8.274-3.572-12.253c-1.754-4.658-0.473-7.486,4.456-8.162
			c2.239-0.307,4.554-0.052,6.835-0.052C49.968,84.309,49.994,84.085,50.021,83.862z"
    />
  </StyledSvg>
);

export default Loading;
