import * as React from "react";

import Spinner from "../Splashes/Spinner";

const Error = React.lazy(() => import("../Splashes/Error"));

interface IErrorBoundary {
  children: React.ReactNode;
}
interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundary, IState> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Suspense fallback={<Spinner />}>
          <Error />
        </React.Suspense>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
